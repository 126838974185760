<template>
	<v-list-group :sub-group="subGroup" :prepend-icon="item.icon" color="primary" v-if="children.length">
		<template #activator>
			<v-list-item-title>{{item.title}}</v-list-item-title>
		</template>
		<nav-menu-item v-for="(item, index) in children" v-bind:key="index" :item="item" sub-group class="pl-6" />
		<v-divider/>
	</v-list-group>
</template>

<script>
export default {
	name: "nav-menu-group",
	components: {
		NavMenuItem: ()=>import('./item.vue'),
	},
	inject: ['authorization'],
	props:{
		subGroup: Boolean,
		item: Object,
	},
	computed: {
		children(){
			if (this.item.children) {
				return this.item.children.filter(this.authorization.filterByRequirements('roles', true));
			}
			return [];
		},
	},
}
</script>